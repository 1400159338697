import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import BackgroundImage from 'gatsby-background-image'

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Button } from "reactstrap"


const TvnPage = () => {

  const data = useStaticQuery(graphql`
    query {
      bgLeft: file(relativePath: { eq: "tvn/bg1.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bgRight: file(relativePath: { eq: "tvn/bg2.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout className="subpage">
      <SEO
        title="TVN: Patent na dom"
        description="Nasza ekipa wspólnie z Marco Belotto oraz Anną Nowak-Ibisz realizuje metamorfozy w programie Patent na dom."
      />
      <section className="tvn pb-0 pt-0">
        <Container fluid>
          <Row>
            <Container className="overlay">
              <Row>
                <Col>
                  <h2 className="section-header">Patent na dom</h2>
                  <p>
                    Wyprodukowaliśmy kilka patentów dla programu TVN "Patent na dom".
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs="12" lg="6">
                  <Container fluid className="m-0 p-0 buttons">
                    <Row>
                      <Col className="m-0 p-0"><h3>Zdjęcia z planu</h3></Col>
                      <Col className="m-0 p-0"><Link to={"patent-na-dom-zdjecia-z-planu"}><Button>Zobacz więcej</Button></Link></Col>
                    </Row>
                  </Container>
                </Col>
                <Col xs="12" lg="6">
                  <Container fluid className="m-0 p-0 buttons">
                    <Row>
                      <Col className="m-0 p-0"><h3>przed / po</h3></Col>
                      <Col className="m-0 p-0"><Link to={"patent-na-dom-przed-po"}><Button>Zobacz więcej</Button></Link></Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
            <Col className="tvn-col m-0 p-0">
              <BackgroundImage fluid={data.bgLeft.childImageSharp.fluid}>
                <div></div>
              </BackgroundImage>
            </Col>
            <Col className="tvn-col m-0 p-0">
              <BackgroundImage fluid={data.bgRight.childImageSharp.fluid}>
                <div></div>
              </BackgroundImage>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default TvnPage